<template>
  <div>
    <Navbar />
    <div class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1 container mx-auto">
      <h1 class="text-2xl 2xl:text-4xl font-semibold mb-4">
        Richieste Canzoni
      </h1>
      <table v-if="songRequests !== null" class="table-auto">
        <thead>
          <tr>
            <th>#</th>
            <th>Data</th>
            <th>Richiesta</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sr in songRequests">
            <td>{{ sr.id }}</td>
            <td>{{ dateFormatter.format(new Date(sr.date)) }}</td>
            <td>{{ sr.request }}</td>
            <td>
              <button
                @click="deleteSongRequest(sr.id)"
                class="btn btn--red disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Elimina
              </button>
            </td>
          </tr>
        </tbody>
        <button
          v-show="songRequests.length % this.itemsPerPage === 0"
          @click="loadMore"
          class="btn btn--red disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Mostra di più
        </button>
      </table>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { getClient } from "@/includes/api";

export default {
  components: { Navbar, Footer },
  data() {
    return {
      songRequests: null,
      page: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    limit() {
      return this.itemsPerPage * this.page;
    },
    offset() {
      return this.itemsPerPage * (this.page - 1);
    },
    dateFormatter() {
      return new Intl.DateTimeFormat("it-IT", {
        timeStyle: "medium",
        dateStyle: "short",
      });
    },
  },
  async mounted() {
    const client = getClient();
    this.songRequests = await client.getSongRequests(this.limit, this.offset);
  },
  methods: {
    async deleteSongRequest(id) {
      if (
        !window.confirm(`Sei sicuro di voler cancellare la richiesta #${id}`)
      ) {
        return;
      }

      const client = getClient();
      await client.deleteSongRequest(id);
      await this.reloadSongRequests();
    },
    async reloadSongRequests() {
      const client = getClient();
      this.songRequests = await client.getSongRequests(this.limit, 0);
    },
    async loadMore() {
      this.page++;
      const client = getClient();
      const res = await client.getSongRequests(this.limit, this.offset);
      this.songRequests.push(...res);
    },
  },
};
</script>
